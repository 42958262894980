import Vue from 'vue'
import VueRouter from 'vue-router'
import { canNavigate} from '@/libs/acl/routeProtection'
import {isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser} from '@/auth/utils'
import store from '@/store'

Vue.use(VueRouter)

const routesList = [
    {
        path: '/email/verify',
        name: 'email verification',
        component: () => import('@/views/email/verify.vue'),
        meta: {
            openForGuest: false,
            pageTitle: 'Weryfikacja adresu e-mail',
            breadcrumb: [
                {
                    text: 'Weryfikacja adresu e-mail',
                    active: true,
                },
            ],
        }
    },
    {
        path: '/email/confirmed',
        name: 'email verified',
        component: () => import('@/views/email/confirmed.vue'),
        meta: {
            openForGuest: false,
            pageTitle: 'Weryfikacja adresu e-mail',
            breadcrumb: [
                {
                    text: 'Weryfikacja adresu e-mail',
                    active: true,
                },
            ],
        }
    },
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/Home.vue'),
        meta: {
            redirectIfLoggedIn: false,
            openForGuest: false,
            pageTitle: 'UG2BS',
            breadcrumb: [
                {
                    text: 'UG2BS',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/moje-kursy',
        name: 'my-courses',
        component: () => import('@/views/MyCourses.vue'),
        meta: {
            redirectIfLoggedIn: false,
            openForGuest: false,
            pageTitle: 'Moje kursy',
            breadcrumb: [
                {
                    text: 'Moje kursy',
                    active: false,
                },
            ],
        },
    },
    {
        path: '/subscription/:slug',
        name: 'subscription',
        component: () => import('@/views/Subscription.vue'),
        meta: {
            redirectIfLoggedIn: false,
            openForGuest: true,
            pageTitle: 'Płatność subskrypcyjna',
            breadcrumb: [
                {
                    text: 'Płatność subskrypcyjna',
                    active: false,
                },
            ],
        },
    },
    {
        path: '/subscription-success',
        name: 'subscription-success',
        component: () => import('@/views/SubscriptionSuccess.vue'),
        meta: {
            redirectIfLoggedIn: false,
            openForGuest: false,
            pageTitle: 'subscription success',
            breadcrumb: [
                {
                    text: 'subscription-success',
                    active: false,
                },
            ],
        },
    },
    {
        path: '/moje-kursy/:slug',
        name: 'single-course',
        component: () => import('@/views/Course.vue'),
        meta: {
            redirectIfLoggedIn: false,
            openForGuest: false,
            layout: 'custom',
            pageTitle: '',
        },
    },
    {
        path: '/starter-poll/:id',
        name: 'starter-poll',
        component: () => import('@/views/starterPoll.vue'),
        meta: {
            redirectIfLoggedIn: false,
            openForGuest: false,
            pageTitle: 'Ankieta kursu indywidualnego',
        },
    },
    {
        path: '/sklep/:slug',
        name: 'shop-item-details',
        component: () => import('@/views/shop/ItemDetails.vue'),
        meta: {
            pageTitle: 'Produkt',
            contentClass: 'ecommerce-application',
            redirectIfLoggedIn: false,
            openForGuest: true,
            breadcrumb: [
                {
                    text: '',
                    active: false,
                },
            ],
        },
    },
    {
        path: '/zamowienia',
        name: 'orders',
        component: () => import('@/views/MyOrders.vue'),
        meta: {
            redirectIfLoggedIn: false,
            openForGuest: true,
            pageTitle: 'Moje zamówienia',
            breadcrumb: [
                {
                    text: 'Moje zamówienia',
                    active: false,
                },
            ],
        },
    },
    {
        path: '/zamowienia/:orderId',
        name: 'orders',
        component: () => import('@/views/MyOrder.vue'),
        meta: {
            redirectIfLoggedIn: false,
            openForGuest: true,
            pageTitle: 'Moje zamówienia',
            breadcrumb: [
                {
                    text: 'Moje zamówienia',
                    active: false,
                },
            ],
        },
    },
    {
        path: '/ustawienia',
        name: 'settings',
        component: () => import('@/views/Settings.vue'),
        meta: {
            redirectIfLoggedIn: false,
            openForGuest: false,
            pageTitle: 'Ustawienia',
            breadcrumb: [
                {
                    text: 'Ustawienia',
                    active: false,
                },
            ],
        },
    },
    {
        path: '/order/:orderId/success',
        name: 'ty',
        component: () => import('@/views/OrderThankYou.vue'),
        meta: {
            redirectIfLoggedIn: false,
            openForGuest: false,
            pageTitle: 'Dziękujemy za zakup',
            breadcrumb: [
                {
                    text: 'Dziękujemy za zakup',
                    active: false,
                },
            ],
        },
    },
    {
        path: '/powiadomienia',
        name: 'notifications',
        component: () => import('@/views/Notifications.vue'),
        meta: {
            redirectIfLoggedIn: false,
            openForGuest: false,
            pageTitle: 'Powiadomienia',
            breadcrumb: [
                {
                    text: 'Powiadomienia',
                    active: false,
                },
            ],
        },
    },
    {
        path: '/polityka-prywatnosci',
        name: 'policy-privacy',
        component: () => import('@/views/PolicyPrivacy.vue'),
        meta: {
            redirectIfLoggedIn: false,
            openForGuest: true,
            pageTitle: 'Polityka prywatnosci',
            breadcrumb: [
                {
                    text: 'Polityka prywatnosci',
                    active: false,
                },
            ],
        },
    },
    {
        path: '/rodo',
        name: 'gdpr',
        component: () => import('@/views/GDPR.vue'),
        meta: {
            redirectIfLoggedIn: false,
            openForGuest: true,
            pageTitle: 'RODO',
            breadcrumb: [
                {
                    text: 'RODO',
                    active: false,
                },
            ],
        },
    },
    {
        path: '/regulamin',
        name: 'regulations',
        component: () => import('@/views/Regulations.vue'),
        meta: {
            redirectIfLoggedIn: false,
            openForGuest: true,
            pageTitle: 'Regulamin',
            breadcrumb: [
                {
                    text: 'Regulamin',
                    active: false,
                },
            ],
        },
    },
    {
        path: '/zamowienie',
        name: 'checkout',
        component: () => import('@/views/shop/Checkout.vue'),
        meta: {
            pageTitle: 'Koszyk',
            contentClass: 'ecommerce-application',
            redirectIfLoggedIn: false,
            openForGuest: true,
            breadcrumb: [
                {
                    text: '',
                    active: false,
                },
            ],
        },
    },
    {
        path: '/resetuj-haslo',
        name: 'reset-password',
        component: () => import('@/views/PasswordReset.vue'),
        meta: {
            redirectIfLoggedIn: true,
            openForGuest: true,
            layout: 'full',
        },
    },
    {
        path: '/logowanie',
        name: 'login',
        component: () => import('@/views/Login.vue'),
        meta: {
            redirectIfLoggedIn: true,
            openForGuest: true,
            layout: 'full',
        },
    },
    {
        path: '/rejestracja',
        name: 'register',
        component: () => import('@/views/Register.vue'),
        meta: {
            redirectIfLoggedIn: true,
            openForGuest: true,
            layout: 'full',
        },
    },
    {
        path: '/error-404',
        name: 'error-404',
        component: () => import('@/views/error/Error404.vue'),
        meta: {
            redirectIfLoggedIn: false,
            openForGuest: true,
            layout: 'full',
            uuu: window.location.referer,
        },
    },
    {
        path: '*',
        redirect: 'error-404',
    },
]

routesList.push(
    {
        path: '/sklep',
        name: 'shop',
        component: () => import('@/views/shop/Shop.vue'),
        meta: {
            pageTitle: 'Sklep',
            contentClass: 'ecommerce-application',
            redirectIfLoggedIn: false,
            openForGuest: true,
            breadcrumb: [
                {
                    text: '',
                    active: false,
                },
            ],
        },
    },
)

const router = new VueRouter({
    mode: 'history',
    base: window.location.pathname.match('/pwa') ? '/pwa' : '/',
    scrollBehavior() {
        return {top: 0}
    },
    routes: routesList,
})

router.beforeEach((to, _, next) => {

    window.scrollTo(0, 0);
    const isLoggedIn = isUserLoggedIn()

    if (!isLoggedIn && !to.meta.openForGuest) {
        next('logowanie')
    }

    // Redirect if logged in
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
        const userData = getUserData()
        next('/')
    }

    return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
})

export default router
